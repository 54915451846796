/* eslint-disable @next/next/no-img-element */
import { useMobxStores } from '@/stores';
import { Affix, Button, Modal, Skeleton } from 'antd';
import { observer } from 'mobx-react-lite';
import FilterFields from './FilterFields';
import SearchList from './SearchList';
import { LoadScriptNext } from '@react-google-maps/api';
import { googleMapsApiKey } from '@/utils/googleMaps';
import dynamic from 'next/dynamic';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/free-solid-svg-icons';
import NeedMoreData from './NeedMoreData';
import { useRouter } from 'next/router';

const SearchMap = dynamic(() => import('@/components2/MapCity'), {
  ssr: false,
});

function CardListC({
  configInfo,
  currentAirport,
  deviceType,
  checkin,
  checkout,
  props,
  ctxLat,
  ctxLng,
  searchType,
  isLanding = false,
}) {
  const { searchStore } = useMobxStores();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const containerRef = useRef(null);
  const router = useRouter();
  const [bottomOffset, setBottomOffset] = useState(0);
  const [bgColor, setBgColor] = useState('bg-gray-100');
  const [newLat, setNewLat] = useState(ctxLat);
  const [newLng, setNewLng] = useState(ctxLng);
  const [hoverIndex, setHoverIndex] = useState(null);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        // Calculate the distance from the bottom of the container to the top of the viewport
        const containerBottom =
          containerRef.current.getBoundingClientRect().bottom;
        const viewportHeight = window.innerHeight;

        // Update 'bottomOffset'
        setBottomOffset(Math.max(0, viewportHeight - containerBottom));

        // change container color when scrolling finished and add 10 pixels to remove jumping effect
        if (containerBottom <= viewportHeight + 10) {
          setBgColor('bg-white');
        } else {
          setBgColor('bg-gray-100');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Skeleton
      paragraph={{ rows: 4 }}
      title
      loading={!searchStore.searchResult.result}
      className="pb-8"
    >
      <div className="mx-auto pb-8 pt-2">
        {!isLanding && (
          <Affix offsetTop={0} style={{ zIndex: 20 }}>
            <div className="bg-white flex flex-row items-center w-full snap-mandatory snap-x overflow-x-auto overflow-y-hidden border border-y border-x-0 ">
              <FilterFields />
            </div>
          </Affix>
        )}

        <div className="flex flex-row w-full relative">
          <div
            ref={containerRef}
            className="md:w-full md:min-w-[42rem] md:max-w-[42.5rem]"
          >
            <SearchList
              configInfo={configInfo}
              checkin={checkin}
              checkout={checkout}
              deviceType={deviceType}
              searchAlternate={null}
              currentAirport={currentAirport}
            />

            <Affix
              offsetBottom={bottomOffset}
              className="w-full md:hidden z-50"
            >
              <div className={`flex justify-center py-4 px-2 ${bgColor}`}>
                <Button
                  type="primary"
                  className={`w-full h-10 text-xl flex flex-row items-center justify-center shadow-xl ${
                    searchStore.selectedFacilities.length > 0 ? '' : ''
                  }`}
                  onClick={showModal}
                >
                  <FontAwesomeIcon
                    icon={faMap}
                    {...props}
                    style={{ width: 28, marginRight: 12 }}
                  />
                  <p className="flex flex-row text-base font-bold">
                    Filter on map
                    {searchStore.selectedFacilities.length > 0 ? (
                      <span className="bg-white w-6 h-6 rounded-full text-primary text-sm p-0.5 ml-3">
                        {searchStore.selectedFacilities.length}
                      </span>
                    ) : (
                      ''
                    )}
                  </p>
                </Button>
              </div>
            </Affix>
            {searchStore.filteredFacilities?.length > 0 && (
              <NeedMoreData airport={currentAirport} />
            )}
          </div>

          {deviceType === 'desktop' && (
            <div className="w-full md:w-full h-screen sticky top-0 right-0 z-0">
              <LoadScriptNext googleMapsApiKey={googleMapsApiKey}>
                <SearchMap
                  markers={searchStore.searchResult.result}
                  currentAirport={currentAirport}
                  selectedFacilities={searchStore.selectedFacilities}
                  triggerIndex={(index) => {
                    searchStore.toogleIndex(index);
                  }}
                  lat={ctxLat}
                  lng={ctxLng}
                  searchType={searchType}
                  // handleMarkerOnClick={handleMarkerOnClick}
                  // handleMouseEnter={handleMouseEnter}
                  // hoverIndex={hoverIndex}
                  // deviceType={deviceType}
                />
              </LoadScriptNext>
            </div>
          )}

          {deviceType === 'mobile' && (
            <Modal
              title={
                <div className="flex flex-row justify-between items-center">
                  {searchStore.selectedFacilities.length > 0 ? (
                    <>
                      <Button
                        className="text-primary text-base border border-primary rounded-full font-medium px-2"
                        onClick={() => searchStore.resetFacilities()}
                        onTouchStart={() => searchStore.resetFacilities()}
                      >
                        Reset
                      </Button>
                    </>
                  ) : (
                    <p className="text-gray-400 text-base border-none px-2">
                      Reset
                    </p>
                  )}
                  <p className="">Select area</p>
                  <Button
                    className="text-primary w-7 h-7 flex justify-center text-sm font-bold border border-primary rounded-full"
                    onClick={handleCancel}
                  >
                    X
                  </Button>
                </div>
              }
              closable={false}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              // centered
              className="search-modal absolute h-full m-0 top-0 bottom-0"
              footer={
                <>
                  <Button
                    type="primary"
                    className="w-full h-10 text-base font-bold rounded-full mt-4"
                    onClick={handleCancel}
                  >
                    View Airport
                  </Button>
                </>
              }
            >
              <div className="md:hidden w-full h-full flex flex-col">
                <LoadScriptNext googleMapsApiKey={googleMapsApiKey}>
                  <SearchMap
                    searchType={searchType}
                    markers={searchStore.searchResult.result}
                    currentAirport={currentAirport}
                    selectedFacilities={searchStore.selectedFacilities}
                    triggerIndex={(index) => {
                      searchStore.toogleIndex(index);
                    }}
                    lat={ctxLat}
                    lng={ctxLng}
                    // handleMarkerOnClick={handleMarkerOnClick}
                    // handleMouseEnter={handleMouseEnter}
                    // hoverIndex={hoverIndex}
                    // deviceType={deviceType}
                  />
                </LoadScriptNext>
                {searchStore.selectedFacilities.length > 0 && (
                  <span className="text-base text-center mt-2">
                    {searchStore.selectedFacilities.length} selected{' '}
                    {searchStore.selectedFacilities.length === 1
                      ? 'area'
                      : 'areas'}{' '}
                    to filter your search
                  </span>
                )}
              </div>
            </Modal>
          )}
        </div>
      </div>
    </Skeleton>
  );
}

export default observer(CardListC);
