/* eslint-disable @next/next/no-img-element */
import { basicPageProps, fetchTemplate, mobileProps } from '@/utils/isServer';
import { absoluteServerPath, CDN2, resolveConfigUrl } from '@helpers/api';
import { cacheFetch, fetchSearchAlternate } from '@helpers/cacheFetch';
import { Button, Collapse, Rate, Card } from 'antd';
import { observer } from 'mobx-react-lite';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import SearchStore from '@/stores/SearchStore';
import { useEffect, useRef, useState } from 'react';
import { useMobxStores } from '@/stores';
import { useRouter } from 'next/router';
import dayjs, { Dayjs } from 'dayjs';
import OldParking from '@pages/myapp2/parking/[friendlyUrl]';
import ExtraLandingComponentA from '@/components/ExtraLandingComponentA';
import useABHook from '@/hooks/useABHook';
import { shuffleReviews } from '@/utils/fakeReviews';
import * as XMLJS from 'xml-js';
import { parkingAbTest } from '@/utils/gaTools';
import { useStorageCheckinCheckout } from '@/hooks/useLocalstorage';
import ExtraLandingComponentB from '@/components/ExtraLandingComponentB';
import ExtraLandingComponentCity from '@/components/ExtraLandingComponentCity';

// window.postMessage('testa')
// window.postMessage('testb')

const SectionItem = dynamic(() => import('@components/Landing/SectionItem'), {
  ssr: true,
});

const { Panel } = Collapse;
const { Meta } = Card;

function extractPrefixSuffix(url) {
  let supressFromHome = false;
  let suffix = '';
  let prefix = '/parking/';
  if (url.indexOf('?') != -1) {
    suffix = url.substring(url.indexOf('?'));
  }
  if (url.indexOf('/myapp') != -1) {
    prefix = '/myapp/parking/';
  }
  if (suffix == '?fromhome=true') {
    suffix = '';
    supressFromHome = true;
  }
  return [prefix, suffix, supressFromHome];
}

const SLUG_MAP = {
  'JohnFKennedy-International-Airport-JFK':
    'John-F-Kennedy-International-Airport-JFK',
  'Penn-Station-Parking': 'Penn-Station-Parking-pns',
  'AJacksonville-International-Airport-JAX':
    'Jacksonville-International-Airport-JAX',
  'TFGreen-International-Airport-PVD': 'Providence-International-Airport-PVD',
  'Southwest-Florida-International-Airport-RSW':
    'Fort-Myers-Southwest-Florida-International-Airport-RSW',
  'Tallahassee-International-Airport-Parking-THL':
    'Tallahassee-International-Airport-Parking-TLH',
};

// // This also gets called at build time
export const getServerSideProps: GetServerSideProps = async ({
  req,
  params,
  query,
}) => {
  const { isMobile, isIos } = mobileProps({ req });
  const {
    configInfo,
    airports,
    baseUrl: baseUrlSite,
  } = await basicPageProps({ req });
  const baseUrl = absoluteServerPath(configInfo);
  const { t } = query; // Preview
  const checkin = query?.checkin ?? null;
  const checkout = query?.checkout ?? null;

  let landing;
  let CUSTOM_SLUGS = {};
  try {
    CUSTOM_SLUGS = JSON.parse(
      await cacheFetch(`${baseUrl}/api/metadata/loadone/custom_slugs`)
    );
  } catch (e) {
    console.log(e);
  }
  const oriFriendlyUrl = params.slug as string;
  let friendlyUrl = oriFriendlyUrl;
  if (CUSTOM_SLUGS[oriFriendlyUrl]) {
    friendlyUrl = CUSTOM_SLUGS[oriFriendlyUrl].target_slug;
  }
  if (SLUG_MAP[friendlyUrl]) {
    friendlyUrl = SLUG_MAP[friendlyUrl];
    const [prefix, suffix] = extractPrefixSuffix(req.url);
    return {
      redirect: {
        destination: prefix + friendlyUrl + suffix,
        permanent: true,
      },
    } as any;
  }
  try {
    if (t === 'preview') {
      const landingResponse = await fetch(
        `${baseUrl}/api/AirportLanding/${friendlyUrl}?t=preview`,
        { ...fetchTemplate }
      );
      landing = await landingResponse.json();
    } else {
      landing = await cacheFetch(
        `${baseUrl}/api/AirportLanding/${friendlyUrl}`
      );
    }
    if (
      t !== 'preview' &&
      landing.airport_frienldy_url &&
      landing.airport_frienldy_url != friendlyUrl
    ) {
      const [prefix, suffix] = extractPrefixSuffix(req.url);
      return {
        redirect: {
          destination: prefix + landing.airport_frienldy_url + suffix,
          permanent: true,
        },
      } as any;
    }
  } catch (e) {
    return {
      redirect: {
        destination: '/',
      },
    } as any;
  }
  const searchAlternate =
    landing.airport_display_type == 1
      ? await fetchSearchAlternate(
          baseUrl,
          0,
          checkin,
          checkout,
          landing.airport_latitude,
          landing.airport_longitude,
          landing.airport_display_type
        )
      : await fetchSearchAlternate(
          baseUrl,
          landing.airport_id,
          checkin,
          checkout,
          null,
          null,
          landing.airport_display_type
        );
  const [prefix, suffix, supressFromHome] = extractPrefixSuffix(req.url);
  if (supressFromHome) {
    return {
      redirect: {
        destination: prefix + landing.airport_frienldy_url + suffix,
        permanent: true,
      },
    } as any;
  }
  if (landing.properties && CUSTOM_SLUGS[oriFriendlyUrl]) {
    if (CUSTOM_SLUGS[oriFriendlyUrl].price) {
      landing.properties.price = CUSTOM_SLUGS[oriFriendlyUrl].price;
    }
    if (CUSTOM_SLUGS[oriFriendlyUrl].seo_title) {
      landing.properties.seo_title = CUSTOM_SLUGS[oriFriendlyUrl].seo_title;
    }
    if (CUSTOM_SLUGS[oriFriendlyUrl].display_name) {
      landing.properties.display_name =
        CUSTOM_SLUGS[oriFriendlyUrl].display_name;
    }
    if (CUSTOM_SLUGS[oriFriendlyUrl].display_name) {
      landing.properties.display_name =
        CUSTOM_SLUGS[oriFriendlyUrl].display_name;
    }
  }

  let dataJsonStr = null;
  if (
    landing?.extra?.extra_enabled &&
    landing?.extra?.extra_google_news_search
  ) {
    const googleNewsLink = `https://news.google.com/rss/search?hl=en-US&gl=US&ceid=US:en&q=${encodeURI(
      landing?.extra?.extra_google_news_search
    )}`;
    const dataFetchContent = await cacheFetch(googleNewsLink, true);
    const dataJson: any = XMLJS.xml2js(dataFetchContent, {
      compact: true,
      ignoreComment: true,
      alwaysChildren: true,
    });
    dataJsonStr = dataJson.rss.channel.item.slice(0, 5).map((x) => {
      x.pubDate._text = dayjs(x.pubDate._text).format('ddd, D MMM YYYY');
      return x;
    });
  }
  const posQuestion = req.url.indexOf('?');
  const strQuery = posQuestion != -1 ? req.url.substring(posQuestion) : '';
  if (strQuery.includes('checkin=') || strQuery.includes('checkout=')) {
    return {
      redirect: {
        destination: `/parking/${landing.airport_frienldy_url}`,
      },
    } as any;
  }
  return {
    props: {
      baseUrlSite,
      landing,
      airports,
      searchAlternate,
      configInfo,
      deviceType: isMobile ? 'mobile' : 'desktop',
      mobileType: isIos ? 'ios' : 'android',
      checkin,
      checkout,
      fixedReviews: shuffleReviews(),
      googleNews: dataJsonStr,
      ctxLat: landing.airport_latitude,
      ctxLng: landing.airport_longitude,
    },
  };
};

// pages/parking/[friendlyUrl].js
function Parking({
  landing,
  baseUrlSite,
  airports,
  searchAlternate,
  configInfo,
  deviceType,
  mobileType,
  checkin,
  checkout,
  fixedReviews,
  googleNews,
  ctxLat,
  ctxLng,
}) {
  const landingAirportInitials = landing?.properties?.airport_initials;
  const selectedAirport =
    landing?.airport_display_type == 1
      ? ''
      : JSON.stringify({
          airportinitials: landingAirportInitials,
          id: landing?.properties?.airport_id,
          value: `${landingAirportInitials} - ${landing?.properties?.airport_name}`,
        });
  useABHook('test1', () => {
    sessionStorage.setItem('abtest', '1');
  });
  const router = useRouter();
  const [checkinCheckout, setCheckinCheckout, setCheckinCheckoutCustom] =
    useStorageCheckinCheckout(landing);
  const dateNow = dayjs();

  const defaultCheckinTime = dateNow
    ? dateNow
        .add(1, 'day')
        .add(30, 'minute')
        .startOf('minute')
        .add(30 - (dateNow.minute() % 30), 'minute')
    : dateNow
        .add(30, 'minute')
        .startOf('minute')
        .add(30 - (dateNow.minute() % 30), 'minute');

  const defaultCheckoutTime =
    landing?.airport_display_type === 0
      ? defaultCheckinTime.add(7, 'day')
      : landing?.airport_display_type === 1
      ? defaultCheckinTime.add(3, 'hour')
      : landing?.airport_display_type === 2 && defaultCheckinTime.add(5, 'day');

  const [icheckin, setICheckin] = useState(
    checkinCheckout.checkin && checkinCheckout.checkintime
      ? dayjs(checkinCheckout.checkin + 'T' + checkinCheckout.checkintime)
      : defaultCheckinTime
  );
  const [icheckout, setICheckout] = useState(
    checkinCheckout.checkout && checkinCheckout.checkouttime
      ? dayjs(checkinCheckout.checkout + 'T' + checkinCheckout.checkouttime)
      : defaultCheckoutTime
  );
  const applyChangeDates = (checkin: Dayjs, checkout: Dayjs) => {
    setCheckinCheckoutCustom(checkin, checkout, 1);

    setICheckin(checkin);
    setICheckout(checkout);
  };

  // if (icheckin.isBefore(dateNow) || icheckout.isBefore(dateNow)) {
  //   icheckin = dateNow;
  //   icheckout = dateNow.add(1, 'day');
  // }

  const [testScope, setTestScope] = useState(null);

  useEffect(() => {
    setTestScope(parkingAbTest());
  }, []);

  return (
    <>
      {!landing?.extra?.extra_enabled && (
        <OldParking
          airports={airports}
          baseUrlSite={baseUrlSite}
          searchAlternate={searchAlternate}
          checkin={icheckin.format('YYYY-MM-DD')}
          checkout={icheckout.format('YYYY-MM-DD')}
          icheckin={icheckin}
          icheckout={icheckout}
          landing={landing}
          mobileType={mobileType}
          deviceType={deviceType}
          configInfo={configInfo}
          applyChangeDates={applyChangeDates}
        />
      )}
      {landing?.extra?.extra_enabled && (
        <>
          {landing?.airport_display_type !== 1 ? (
            <>
              {testScope ? (
                // new parkingat
                <ExtraLandingComponentA
                  airports={airports}
                  baseUrlSite={baseUrlSite}
                  searchAlternate={searchAlternate}
                  checkin={checkin}
                  checkout={checkout}
                  icheckin={icheckin}
                  icheckout={icheckout}
                  landing={landing}
                  deviceType={deviceType}
                  selectedAirport={selectedAirport}
                  configInfo={configInfo}
                  fixedReviews={fixedReviews}
                  googleNews={googleNews}
                  applyChangeDates={applyChangeDates}
                />
              ) : (
                // old parkingat
                <ExtraLandingComponentB
                  airports={airports}
                  baseUrlSite={baseUrlSite}
                  searchAlternate={searchAlternate}
                  checkin={checkin}
                  checkout={checkout}
                  icheckin={icheckin}
                  icheckout={icheckout}
                  landing={landing}
                  deviceType={deviceType}
                  selectedAirport={selectedAirport}
                  configInfo={configInfo}
                  fixedReviews={fixedReviews}
                  googleNews={googleNews}
                  applyChangeDates={applyChangeDates}
                  mobileFeeDiscount={configInfo.mobileFeeDiscount}
                />
              )}
            </>
          ) : (
            // old parkingat
            <ExtraLandingComponentCity
              airports={airports}
              baseUrlSite={baseUrlSite}
              searchAlternate={searchAlternate}
              checkin={checkin}
              checkout={checkout}
              icheckin={icheckin}
              icheckout={icheckout}
              landing={landing}
              deviceType={deviceType}
              selectedAirport={selectedAirport}
              configInfo={configInfo}
              fixedReviews={fixedReviews}
              googleNews={googleNews}
              applyChangeDates={applyChangeDates}
              mobileFeeDiscount={configInfo.mobileFeeDiscount}
              ctxLat={ctxLat}
              ctxLng={ctxLng}
            />
          )}
        </>
      )}
    </>
  );
}

Parking.layout = 'LandingPageLayout';

export default Parking;
