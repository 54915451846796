import { isServer } from './isServer';

const DEFAULT_ID = '1';

export function getTestScope(variants = 2, df = DEFAULT_ID) {
  if (!isServer) {
    try {
      const randomId = parseInt(
        localStorage.getItem('randomId') ?? df
      ) as number;
      return randomId % variants;
    } catch (e) {
      // console.log(e)
    }
  }
  return 0;
}

export function homeAbTest(): number {
  const testScope = getTestScope(2, DEFAULT_ID);
  if (!isServer) {
    window['dataLayer']?.push({
      event: 'home_ab_test',
      experimentNameC3: 'first_day',
      experimentIDC3: testScope == 1 ? 'variant1' : 'control',
    });
  }
  return testScope;
}

const PARKING_DEFAULT_ID = '1';

export function parkingAbTest(): number {
  const testScope = getTestScope(2, PARKING_DEFAULT_ID);
  if (!isServer) {
    window['dataLayer']?.push({
      event: 'confirmed_ab_test',
      experimentName: 'newParking',
      experimentID: testScope ? 'variant1' : 'control',
    });
  }
  return testScope;
}

const PARKINGAT_DEFAULT_ID = '1';

export function parkingAtAbTest(): number {
  const testScope = getTestScope(2, PARKINGAT_DEFAULT_ID);
  if (!isServer) {
    window['dataLayer']?.push({
      event: 'parkingat_ab_test',
      experimentNameC2: 'newParkingAt',
      experimentIDC2: testScope ? 'variant1' : 'control',
    });
  }
  return testScope;
}
