import { useMobxStores } from '@stores/index';
import SearchStore from '@/stores/SearchStore';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { IFacility } from '@/types';
import Head from 'next/head';

interface ITitles {
  lowPrice: string;
  metaTitle: string;
  description: string;
}

export function checkLowPrice(
  searchAlternate: IFacility[],
  landing: any,
  setLowestPrice: (n: number) => void
): ITitles {
  const arrr = searchAlternate
    ?.filter((x) => x.date_sold_out == 0)
    .sort(
      (a, b) =>
        (a.facility_selling_price || a.facility_solid_selling_price) -
        (b.facility_selling_price || b.facility_solid_selling_price)
    );
  if (arrr?.length > 0) {
    console.log(arrr[0]);
    setLowestPrice(arrr[0].facility_selling_price);
    const lowPrice = (
      arrr[0].facility_selling_price || arrr[0].facility_solid_selling_price
    ).toFixed(2);
    return {
      lowPrice: `Cheap $ ${lowPrice} ${
        landing.properties.airport_name
      } Parking | Lowest Cost ${
        landing.properties.airport_initials
      } ${getAirportType(landing)}Parking | ${
        landing.properties.airport_initials
      } Parking & Shuttle`,
      metaTitle: `${landing.properties.airport_name} ${getAirportType(
        landing
      )}Parking (${
        landing.properties.airport_initials
      }) | From $${lowPrice}/Day`,
      description: `Find cheap short and long-term parking rates near ${landing.properties.airport_name} (${landing.properties.airport_initials}). Our discount lots offer free shuttles, 24/7 security, and easy cancellation.`,
    };
  }
  setLowestPrice(null);
  return null;
}

function getAirportType(landing: any) {
  return landing.airport_display_type == 1
    ? 'City '
    : landing.airport_display_type == 2
    ? 'Cruise '
    : '';
}

function TitleHead({
  landing,
  searchAlternate,
  setLowestPrice,
}: {
  landing: any;
  searchAlternate: { result: IFacility[] };
  setLowestPrice: (n: number) => void;
}) {
  const {
    searchStore,
  }: {
    searchStore: SearchStore;
  } = useMobxStores();

  const landingAirportInitials = landing?.properties?.airport_initials;
  const pageTitle = landing?.properties?.seo_title
    ? landing?.properties?.seo_title
    : `${landing?.properties?.airport_name ?? ''} (${
        landingAirportInitials ?? ''
      }) | On Air Parking Cheap Discount Rates`;
  const metaStaticBlurb = `Cheap ${
    landing?.properties?.airport_name ?? ''
  } Parking At ${
    landingAirportInitials ?? ''
  }. We know, the price looks to good to be true! Don't worry, it's true, and it's a great location. In fact you'll be parking at a professional parking facility. You'll be e-mailed where you're parking for $2.75 a day once you make the purchase on our site. Reserve today!`;
  const metaDescription = landing?.properties?.landing_seo_description
    ? landing?.properties?.landing_seo_description
    : metaStaticBlurb;
  const [titles, setTitles] = useState<ITitles>(
    checkLowPrice(searchAlternate?.result, landing, setLowestPrice)
  );
  useEffect(() => {
    let newTitles = checkLowPrice(
      searchStore.searchResult?.result ?? searchAlternate?.result,
      landing,
      setLowestPrice
    );
    if (!newTitles?.lowPrice) {
      newTitles = checkLowPrice(
        searchAlternate?.result,
        landing,
        setLowestPrice
      );
    }
    setTitles(newTitles);
  }, [
    searchStore.isLoading,
    searchAlternate?.result,
    landing,
    searchStore.searchResult?.result,
    searchStore.filteredFacilities,
  ]);
  return (
    <>
      <Head>
        <title>{titles?.metaTitle ?? pageTitle}</title>
        <meta
          property="og:title"
          content={titles?.metaTitle ?? pageTitle}
          key="og:title"
        />
        <meta
          property="og:description"
          content={titles?.description ?? metaDescription}
          key="og:description"
        />
        <meta
          name="description"
          content={titles?.description ?? metaDescription}
          key="description"
        />
      </Head>
    </>
  );
}

export default observer(TitleHead);
