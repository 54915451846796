type FormatCurrencyProps = {
  value: number;
  locale: string;
  currency: string;
};
export const formatCurrency = ({
  value,
  locale,
  currency,
}: FormatCurrencyProps) =>
  new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value);

export const isMdQuery = () => {
  return window.matchMedia('(max-width: 768px)').matches;
};
export const formatWeekdayName = (day: number) => {
  switch (day) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
  }
  return day;
};
